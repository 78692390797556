
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Footer, { AboutBlurb, CallBlurb } from '~2-components/Footer/Footer';
import { dummyFooterData } from '~data/dummyFooterData';
import '~styles/styles.scss';
import appleFavicon from '~assets/favicon/apple-touch-icon.png';
import favicon32 from '~assets/favicon/favicon-32x32.png';
import favicon16 from '~assets/favicon/favicon-16x16.png';
import FeatureCTA from '~2-components/FeatureCTA/FeatureCTA';
import { dummyFeatureCTAData } from '~data/dummyFeatureCTAData';
import StickyEnquiryCta from '~2-components/StickyEnquiryCta/StickyEnquiryCta';

export const RenderedContext = React.createContext();

const Layout = ({ children }) => {
    const metaData = useStaticQuery(
        graphql`
        {
            site {
                siteMetadata {
                    title
                }
            }
        }
        `
    );

    const projectTitle = metaData.site.siteMetadata.title;

    return (
        <>
            <Helmet
                title={projectTitle}
                meta={[
                    { name: `description`, content: `Gatsby starter site with Kentico Kontent` },
                    { name: `keywords`, content: `Kentico Kontent, Gatsby, starter` },
                ]}
            >
                <link rel="apple-touch-icon" sizes="180x180" href={appleFavicon} />
                <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
                <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
                <link rel="manifest" href="/static/favicon/site.webmanifest" />
            </Helmet>

            {children}

            <FeatureCTA {...dummyFeatureCTAData} />

            <Footer {...dummyFooterData}>
                <AboutBlurb>
                    <p>Adviceline Injury Lawyers is a division of Holding Redlich © 2019<br/>Level 8, 555 Bourke Street, Melbourne, 3000<br/>Read our&nbsp;<a href="/privacy-policy">privacy policy</a></p>
                </AboutBlurb>
                <CallBlurb>
                    <p>At Adviceline our lawyers answer the phone so that you receive free legal advice straight away. No Win, No Fee</p>
                </CallBlurb>
            </Footer>

            <StickyEnquiryCta />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.array,
};

export default Layout;
