export const dummyTeamListingData = [
    {
        imgUrl: 'https://source.unsplash.com/featured/152x152?people',
        imgAlt: 'Team member image',
        accredited: 'yes',
        firstName: 'Bree',
        lastName: 'Knoester',
        position: 'Managing Partner',
        phone: '(03) 9321 9988',
        email: 'bree.knoester@alil.com.au',
        linkedIn: '#',
        urlSlug: '/bree-knoester',
        urlText: 'View Profile'
    },
    {
        imgUrl: 'https://source.unsplash.com/featured/152x152?people',
        imgAlt: 'Team member image',
        accredited: 'no',
        firstName: 'Tina',
        lastName: 'Toutzaris-Sabo',
        position: 'Senior Associate',
        phone: '(03) 9321 9988',
        email: 'tina.toutzaris-sabo@alil.com.au',
        linkedIn: '#',
        urlSlug: '/tina-toutzaris-sabo',
        urlText: 'View Profile'
    },
    {
        imgUrl: 'https://source.unsplash.com/featured/152x152?people',
        imgAlt: 'Team member image',
        accredited: 'no',
        firstName: 'Lachlan',
        lastName: 'Hicks',
        position: 'Hearing Loss Coordinator',
        phone: '(03) 9321 9988',
        email: 'lachlan.hicks@alil.com.au',
        linkedIn: '#',
        urlSlug: '/lachlan-hicks',
        urlText: 'View Profile'
    },
    {
        imgUrl: 'https://source.unsplash.com/featured/152x152?people',
        imgAlt: 'Team member image',
        accredited: 'no',
        firstName: 'Genna',
        lastName: 'Angelowitsch',
        position: 'Special Counsel',
        phone: '(03) 9321 9988',
        email: 'genna.angelowitsch@alil.com.au',
        linkedIn: '#',
        urlSlug: '/genna-angelowitsch',
        urlText: 'View Profile'
    }
];
