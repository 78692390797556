export const dummyTestimonials = [
    {
        content: 'What a pleasure it’s been to work with Shyla, Nicole and Lauren. I just want to thank you for your commitment, honesty and graciousness. You guys have helped me close a door, move forward AND with my dignity in place. Thank you so much.',
        name: 'Nataly Ceballos'
    },
    {
        content: 'Just a short note to convey my sincere thanks for all your hard work and support during such a difficult period.',
        name: 'Injured worker, Mentone'
    },
    {
        content: 'The lawyers and staff were great. You can sit back and relax and let the team do the worrying for you.',
        name: 'Terrance Macartney'
    }
];
