export const accordionItemsExample = [
    {
        title: 'I have been injured at work – what should I do?',
        content: `<p>If you have been injured at work, you need to notify your employer within 30 days of you becoming aware of your work related injury. A good way to formally notify your employer is to fill out the Register of Injuries, which must be kept at each workplace.</p>
        <p>You should seek any medical treatment necessary. It is helpful to tell your doctor or health provider the circumstances of your work-related injury from the first consultation.</p>`,
    },
    {
        title: 'How long do I have to make a WorkCover claim?',
        content: `<p>You should seek any medical treatment necessary. It is helpful to tell your doctor or health provider the circumstances of your work-related injury from the first consultation.</p>`,
    },
    {
        title: 'How long does the authorised insurer have to accept or reject my claim?',
        content: `<p>If you have been injured at work, you need to notify your employer within 30 days of you becoming aware of your work related injury. A good way to formally notify your employer is to fill out the Register of Injuries, which must be kept at each workplace.</p>
        <p>You should seek any medical treatment necessary. It is helpful to tell your doctor or health provider the circumstances of your work-related injury from the first consultation.</p>
        <p>If you have been injured at work, you need to notify your employer within 30 days of you becoming aware of your work related injury. A good way to formally notify your employer is to fill out the Register of Injuries, which must be kept at each workplace.</p>`,
    },
    {
        title: 'Who is covered under WorkCover?',
        content: `<p>If you have been injured at work, you need to notify your employer within 30 days of you becoming aware of your work related injury. A good way to formally notify your employer is to fill out the Register of Injuries, which must be kept at each workplace.</p>`,
    },
];
