import React from 'react';
import StaticLayout from '~layouts/static-layout';
import Header from '~2-components/Header/Header';
import { RenderedContext } from '~layouts/layout';
import VideoPanel from '~2-components/VideoPanel/VideoPanel';
import { allanExample } from '~data/videoExample';
import { PracticeAreaDetailData } from '~data/practiceAreaDetailData';
import FeatureImageAndText from '~2-components/FeatureImageAndText/FeatureImageAndText';
import { featureImageAndTextWithHeading } from '~data/featureImageAndTextData';
import PageSection from '~2-components/PageSection/PageSection';
import Accordion from '~2-components/Accordion/Accordion';
import AccordionItem from '~2-components/Accordion/AccordionItem';
import { accordionItemsExample } from '~data/accordionItemsExample';
import CheckList from '~2-components/CheckList/CheckList';
import LinkList from '~2-components/LinkList/LinkList';
import TileContainer from '~2-components/TileContainer/TileContainer';
import ContentIconTile from '~2-components/ContentIconTile/ContentIconTile';
import OptionsTile from '~2-components/OptionsTile/OptionsTile';
import { sgNumberedContentIconTiles } from '~data/sgNumberedContentIconTiles';
import ProfileSlider from '~2-components/ProfileSlider/ProfileSlider';
import { dummyTeamListingData } from "~data/dummyTeamListingData";
import AwardsAndAccolades, { ColumnOne, ColumnTwo } from '~2-components/AwardsAndAccolades/AwardsAndAccolades';
import { AwardsAndAccoladesData } from '~data/awardsAndAccoladesData';
import AwardItem from '~2-components/AwardItem/AwardItem';
import TestimonialCarousel from '~2-components/TestimonialCarousel/TestimonialCarousel';
import { dummyTestimonials } from '~data/dummyTestimonials';

const PracticeAreaLanding = () => (
    <StaticLayout>
        <RenderedContext.Provider value={PracticeAreaDetailData}>
            <Header />
        </RenderedContext.Provider>

        <div className="page-content">
            <div className="container">
                <FeatureImageAndText {...featureImageAndTextWithHeading} />

                <PageSection
                    backgroundTheme='grey'
                >
                    <h2>Our WorkCover lawyers can help with a claim for</h2>
                    <CheckList>
                        <ul>
                            <li>Aggravation of a physical or mental injury</li>
                            <li>Physical injury, disability or death caused in the workplace or ‘over the course of employment’.</li>
                            <li>Psychological condition due to workplace stress or bullying.</li>
                            <li>Loss of hearing from noise exposure in the workplace</li>
                            <li>Disease diagnosis after exposure in the workplace to hazardous materials</li>
                        </ul>
                    </CheckList>
                    <LinkList
                        title="We can also provide advice and assistance about"
                    >
                        <ul>
                            <li><a href="#">Making a WorkCover claim</a></li>
                            <li><a href="#">Terminated payments</a></li>
                            <li><a href="#">Serious injury applications</a></li>
                            <li><a href="#">Rejected WorkCover claims</a></li>
                            <li><a href="#">Return to work obligations</a></li>
                            <li><a href="#">Financial assistance to the family of a deceased worker</a></li>
                        </ul>
                    </LinkList>
                </PageSection>
                <PageSection>
                    <h2>Your Options</h2>
                    <p>If you've been injured at work, you may be able to bring one or more claims based on the severity of your injury/illness and whether your employer was at fault.</p>
                    <TileContainer
                        columns="three_columns"
                    >
                        <OptionsTile
                            title="WorkCover No fault benefits"
                        >
                            <p>WorkCover benefits include "weekly payments" of lost income and a financial contribution towards medical expenses such as:</p>
                            <ul>
                                <li>Ambulance and hospital expenses</li>
                                <li>Doctors and other medical attendances (including travel expenses)</li>
                                <li>Medications and aides</li>
                                <li>Personal and household expenses</li>
                                <li>Rehabilitation costs (including counselling and household assistance).</li>
                            </ul>
                        </OptionsTile>
                        <OptionsTile
                            title="WorkCover No fault benefits"
                        >
                            <p>WorkCover benefits include "weekly payments" of lost income and a financial contribution towards medical expenses such as:</p>
                            <ul>
                                <li>Ambulance and hospital expenses</li>
                                <li>Doctors and other medical attendances (including travel expenses)</li>
                                <li>Medications and aides</li>
                                <li>Personal and household expenses</li>
                                <li>Rehabilitation costs (including counselling and household assistance).</li>
                            </ul>
                        </OptionsTile>
                        <OptionsTile
                            title="WorkCover No fault benefits"
                        >
                            <p>WorkCover benefits include "weekly payments" of lost income and a financial contribution towards medical expenses such as:</p>
                            <ul>
                                <li>Ambulance and hospital expenses</li>
                                <li>Doctors and other medical attendances (including travel expenses)</li>
                                <li>Medications and aides</li>
                                <li>Personal and household expenses</li>
                                <li>Rehabilitation costs (including counselling and household assistance).</li>
                            </ul>
                        </OptionsTile>
                    </TileContainer>
                    <VideoPanel {...allanExample} />
                </PageSection>
                <PageSection
                    backgroundTheme='grey'
                >
                    <h2>How do I make a WorkCover claim?</h2>
                    <TileContainer
                        columns="two_columns"
                    >
                        {sgNumberedContentIconTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                    tileLayout={tile.tileLayout}
                                    step={tile.step}
                                />
                            );
                        })}
                    </TileContainer>
                </PageSection>
                <PageSection>
                    <h2>Frequently Asked Questions</h2>
                    <p><a href="#">Read all WorkCover FAQs</a></p>

                    <Accordion>
                        {accordionItemsExample.map((item, i) => {
                            return <AccordionItem key={i} {...item} />;
                        })}
                    </Accordion>
                </PageSection>
                <PageSection
                    backgroundTheme='grey'
                >
                    <h2>Meet our Workers Compensation lawyers</h2>
                    <p><a href="#">view the full team</a></p>
                    <ProfileSlider
                        tiles={dummyTeamListingData}
                    />
                </PageSection>
                <PageSection>
                    <h2>Latest News</h2>
                    <p><a href="#">Read more WorkCover articles</a></p>
                    <h2>LATEST NEWS TBD</h2>
                </PageSection>
                <AwardsAndAccolades
                    title="Awards and Accolades"
                    image={AwardsAndAccoladesData.backgroundImage}
                >
                    <ColumnOne>
                        <h3>Doyle's Guide</h3>
                        {AwardsAndAccoladesData.column1Awards.map((item, i) => {
                            return <AwardItem key={i} {...item} />;
                        })}
                    </ColumnOne>
                    <ColumnTwo>
                        <h3>Best Lawyers</h3>
                        {AwardsAndAccoladesData.column2Awards.map((item, i) => {
                            return <AwardItem key={i} {...item} />;
                        })}
                    </ColumnTwo>
                </AwardsAndAccolades>
                <TestimonialCarousel
                    testimonials={dummyTestimonials}
                />
            </div>

        </div>
    </StaticLayout>
);

export default PracticeAreaLanding;

