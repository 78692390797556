export const PracticeAreaDetailData = {
    title: 'I’ve been injured at work',
    staticIntroduction: `<p>If you or a family member has suffered a work-related injury or illness, our Workers Compensation lawyers can help.</p>`,
    hasImage: true,
    heroImage: {
        mobileImage: 'https://source.unsplash.com/featured/767x664?people',
        tabletImage: 'https://source.unsplash.com/featured/1023x800?people',
        desktopImage: 'https://source.unsplash.com/featured/1600x800?people',
    },
    cta1: {
        txt: ``,
        url: ``
    },
    cta2: {
        txt: '',
        url: ''
    },
    mediaQuery: '',
    showContact: '',
    breadcrumbs: [
        {
            title: 'Home'
        }, {
            title: `I've Been Injured`
        }, {
            title: 'In the Workplace',
            isActive: true
        }
    ]
};


